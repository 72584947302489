/**
 * @author 季悠然
 * @date 2022-04-28
 */

export default function Photo(props) {
    return (
        <div className={"widget widget-photo " + props.size} style={{backgroundImage: "url(" + props.url + ")"}}>
            <div>
                <p>{props.desc}</p>
            </div>
        </div>
    )
}
/**
 * @author 季悠然
 * @date 2022-04-28
 */

export default function RandomPost(props) {
    return (
        <div className="widget widget-random-post">
            <h3>随机文章</h3>
            {
                new Array(parseInt(props.len)).fill(0).map((_, i) => (
                    <div className="item" key={'article' + i}>
                        <h4>测试文章 {i + 1} </h4><span>刚刚发布</span>
                    </div>
                ))
            }
        </div>
    )
}
/**
 * @author 季悠然
 * @date 2022-04-28
 */
import {useEffect, useState} from "react";
import axios from "axios";

export default function Hitokoto(props) {

    const [data, setData] = useState({hitokoto: "加载中", from_who: "加载中"})

    useEffect(()=>{
        let cate = props.cate.split(',')
        let queryStr = ''

        cate.forEach(e => {
            queryStr += '&c=' + e
        })

        axios.get('https://v1.hitokoto.cn?encode=json' + queryStr).then(res => {
            setData(res.data)
        })
    }, [])

    return (
        <div className="widget widget-hitokoto">
            <div>
                <p>{data.hitokoto}</p>
                <span>{data.from_who}</span>
            </div>
        </div>
    )
}
/**
 * @author 季悠然
 * @date 2022-04-29
 */

export default function Cate(props) {
    return (
        <div className={"widget category-list " + props.size}>
            <div className="category-content">
                <li><a href={props.content[0].url}>{props.content[0].name}</a></li>
                <li><a href={props.content[1].url}>{props.content[1].name}</a></li>
                <li><a  href={props.content[2].url}>{props.content[2].name}</a></li>
            </div>
        </div>
    )
}
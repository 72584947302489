/**
 * @author 季悠然
 * @date 2022-04-28
 */

export default function Comments(props) {
    return (
        <div className="widget widget-recent-comment">
            {
                new Array(parseInt(props.len)).fill(0).map((e, i) => (
                    <div className="recent-comment-item" key={"comments" + i}>
                        <img className="avatar" src="https://sdn.geekzu.org/avatar/87e0f8d2f0f70987061cec6376cb7f97?s=60&r=G&d="
                             alt="测试用户" title="测试用户"/>
                            <div className="recent-comment-content">
                                <div className="meta">
                                    <span>测试用户</span>
                                    <span>{i+1}分钟前</span>
                                </div>
                                <p>测试评论内容！</p>
                                <div className="meta">
                                    <span></span>
                                    <span>《测试文章》</span>
                                </div>
                            </div>
                    </div>
                ))
            }
        </div>
    )
}
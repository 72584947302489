/**
 * @author 季悠然
 * @date 2022-04-28
 */

export default function Video(props) {
    return (
        <div className={"widget widget-photo " + props.size}>
            <video className="widget-video" loop muted autoPlay src={props.url}></video>
            <div>
                <p>{props.desc}</p>
            </div>
        </div>
    )
}
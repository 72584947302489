import 'reset-css'
import './App.scss'
import Editor from "./pages/Editor.jsx";

function App() {
  return (
    <div className="App">
      <Editor/>
    </div>
  );
}

export default App

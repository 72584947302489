/**
 * @author 季悠然
 * @date 2022-04-28
 */
import {useState} from "react";

export default function Like(props) {
    const [cnt, setCnt] = useState(0)

    return (
        <div className={"widget " + props.size} id="DoYouLikeMe"
             onClick={()=>{setCnt(cnt + 1)}}>
            <p>
                ❤ <span>{cnt}</span>
            </p>
        </div>
    )
}
/**
 * @author 季悠然
 * @date 2022-04-28
 */

import {useState} from "react"
import {Button, Col, Input, Row, Space, TextArea} from "@douyinfe/semi-ui"
import componentData from '../config/conponents.json'
import { IconPlus } from '@douyinfe/semi-icons'
import Photo from "../components/Photo"
import Video from "../components/Video"
import Like from "../components/Like"
import RandomPost from "../components/RandomPost"
import Comments from "../components/Comments"
import Hitokoto from "../components/Hitokoto";
import Cate from "../components/Cate";

function Editor() {

    const [config, updateConfig] = useState([])
    const [currentIndex, setIndex] = useState(-1)
    const [currentConfig, setConfig] = useState({})

    const configNew = (idx) => {
        setIndex(idx)
        setConfig({
            ...componentData[idx].custom
        })
    }

    const addNew = (key) => {
        if(key === 'cate')
        {
            updateConfig(config.concat([{
                name: key,
                size: currentConfig.size,
                content: [
                    {
                        name: currentConfig.url1.split(',')[0],
                        url: currentConfig.url1.split(',')[1],
                    },
                    {
                        name: currentConfig.url2.split(',')[0],
                        url: currentConfig.url2.split(',')[1],
                    },
                    {
                        name: currentConfig.url3.split(',')[0],
                        url: currentConfig.url3.split(',')[1],
                    }
                ]
            }]))
        }
        else
            updateConfig(config.concat([{name: key, ...currentConfig}]))
    }

    const handleInput = (value, key) => {
        let obj = JSON.parse(JSON.stringify(currentConfig))
        obj[key] = value
        setConfig(obj);
    }

    return (
        <div className={"editor"}>
            <Row>
                <Col span={4}>
                    <div className="container add">
                        <h2>G editor</h2>
                        <p>快速配置G主题的小组件！</p>

                        <div className="select">
                            <h3>小组件列表</h3>
                            <Space wrap={true}>
                                {
                                    componentData.map((e, i) => (<Button key={e.name} onClick={()=>{configNew(i)}}>{e.name}</Button>))
                                }
                            </Space>
                        </div>

                        {
                            currentIndex !== -1 ? (
                                <div className="config">
                                    <h3>配置 {componentData[currentIndex].name} 小组件</h3>
                                    {
                                        Object.keys(currentConfig).map(k => (
                                            <div key={k} className={"single"}>
                                                <p>{k}</p>
                                                <Input value={currentConfig[k]} onChange={(v)=>{handleInput(v, k)}}/>
                                            </div>
                                        ))
                                    }
                                    <p style={{textAlign: "right"}}>
                                        <Button icon={<IconPlus />} onClick={()=>{addNew(componentData[currentIndex].key)}}>添加</Button>
                                    </p>
                                </div>
                            ) : (
                                <div className="config">
                                    <h3>配置小组件</h3>
                                    <p>先从上面的组件列表里面选择一个吧</p>
                                </div>
                            )
                        }
                    </div>
                </Col>
                <Col span={12}>
                    <div className="container">
                        <div className="preview">
                            <h3>预览</h3>

                            {
                                config.length === 0 ? (
                                    <p>配置为空</p>
                                ) : (
                                    <div className="widgets-preview">
                                        {
                                            config.map((e, i) => {
                                                switch (e.name) {
                                                    case 'photo':
                                                        return <Photo key={e.name+ i} url={e.url} desc={e.desc} size={e.size}/>
                                                    case 'video':
                                                        return <Video key={e.name+ i} url={e.url} desc={e.desc} size={e.size}/>
                                                    case 'like':
                                                        return <Like key={e.name+ i} size={e.size}/>
                                                    case 'randomPost':
                                                        return <RandomPost key={e.name+ i} len={e.len}/>
                                                    case 'comments':
                                                        return <Comments key={e.name+ i} len={e.len}/>
                                                    case 'hitokoto':
                                                        return <Hitokoto key={e.name+ i} cate={e.cate}/>
                                                    case 'cate':
                                                        return <Cate key={e.name+ i} content={e.content} size={e.size}/>
                                                    default:
                                                        return ''
                                                }
                                            })
                                        }
                                    </div>
                                )
                            }
                        </div>
                    </div>
                </Col>
                <Col span={6}>
                    <div className="container">
                        <h3>JSON代码</h3>
                        <TextArea value={JSON.stringify(config)} rows={20}/>
                    </div>
                </Col>
            </Row>
        </div>
    );
}

export default Editor